import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rkb-page-title',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './page-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  @Input()
  title: string;

  @Input()
  logo: string;

  @Input()
  editable = false;
}
