import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'rkb-svg-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SvgIconComponent implements OnChanges {
  @Input()
  public icon?: string;

  @Input()
  active = false;

  @Input()
  white = false;

  @Input()
  dark = false;

  /*
   * Custom svg size
   */
  @Input()
  size: string;

  /*
   * Custom svg path colors
   */
  @Input()
  color: string;

  /*
   * Icons with state active/inactive, we don't edit other icons
   */
  @Input()
  state = false;

  public svgIcon: SafeHtml;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
  ) {}

  public ngOnChanges(): void {
    if (this.size) {
      this.elRef.nativeElement.style.setProperty('--size', this.size);
    }
    if (this.color) {
      this.elRef.nativeElement.style.setProperty('--color', this.color);
    }

    if (!this.icon) {
      this.svgIcon = '';
      return;
    }

    this.httpClient
      .get(`assets/icons/${this.icon + (this.active ? '-active' : '')}.svg`, {
        responseType: 'text',
      })
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        this.cdr.detectChanges();
      });
  }

  getClasses() {
    return {
      'with-state': this.state,
      'force-white': this.white,
      'force-dark': this.dark,
      'custom-size': !!this.size,
      'custom-color': !!this.color,
    };
  }
}
