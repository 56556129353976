export interface OrgApiItem {
    id: string;
    name: string;
    allowed_clusters: string[];
    created_at: string;
    updated_at: string;
}

export interface Org {
    id: string;
    name: string;
    allowedClusters: string[];
    createdAt: Date;
    updatedAt: Date;
}

export function convertToOrg(apiItem: OrgApiItem): Org {
    return {
        id: apiItem.id,
        name: apiItem.name,
        allowedClusters: apiItem.allowed_clusters,
        createdAt: new Date(apiItem.created_at),
        updatedAt: new Date(apiItem.updated_at),
    };
}

export function convertToApiOrgItem(org: Partial<Org>): Partial<OrgApiItem> {
    return {
        id: org.id,
        name: org.name,
        allowed_clusters: org.allowedClusters,
        created_at: org.createdAt?.toISOString(),
        updated_at: org.updatedAt?.toISOString(),
    };
}