import { Brand } from '../../brand/models/brand.model';
import { Org } from '../models/org.model';
import { Program } from '../models/program.model';

const tag = '[Org]';

export class FetchOrgRequest {
  static readonly type = `${tag} fetch org request`;
  constructor(public orgId: string) {}
}

export class FetchOrgSuccess {
  static readonly type = `${tag} fetch org success`;
  constructor(public org: Org) {}
}

export class FetchOrgError {
  static readonly type = `${tag} fetch org error`;
  constructor(public error: any) {}
}

export class FetchOrgBrandsRequest {
  static readonly type = `${tag} fetch org brands request`;
}

export class FetchOrgBrandsSuccess {
  static readonly type = `${tag} fetch org brands success`;
  constructor(public brands: Brand[]) {}
}

export class FetchOrgBrandsError {
  static readonly type = `${tag} fetch org brands error`;
  constructor(public error: any) {}
}

export class FetchOrgProgramsRequest {
  static readonly type = `${tag} fetch org programs request`;
  constructor() {}
}

export class FetchOrgProgramsSuccess {
  static readonly type = `${tag} fetch org programs success`;
  constructor(public programs: Program[]) {}
}

export class FetchOrgProgramsError {
  static readonly type = `${tag} fetch org programs error`;
  constructor(public error: any) {}
}

export class SetCurrentOrgId {
  static readonly type = `${tag} set current org id`;
  constructor(public orgId: string) {}
}

export class CreateProgramRequest {
  static readonly type = `${tag} create program request`;
  constructor(public program: Partial<Program>) {}
}

export class CreateProgramSuccess {
  static readonly type = `${tag} create program success`;
  constructor(public newProgramId: any) {}
}

export class CreateProgramError {
  static readonly type = `${tag} create program error`;
  constructor(public error: any) {}
}
export class CreateBrandRequest {
  static readonly type = `${tag} create brand request`;
  constructor(public brand: Partial<Brand>) {}
}

export class CreateBrandSuccess {
  static readonly type = `${tag} create brand success`;
  constructor(public newBrandId: string) {}
}

export class CreateBrandError {
  static readonly type = `${tag} create brand error`;
  constructor(public error: any) {}
}

export class ResetNewBrandId {
  static readonly type = `${tag} reset new brand id`;
  constructor() {}
}

export class ResetNewProgramId {
  static readonly type = `${tag} reset new program id`;
  constructor() {}
}