import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

type Variants = 'primary' | 'secondary';

interface ActionItem {
  text: string;
  action: () => void;
}

@Component({
  selector: 'rkb-button',
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule, MatMenuModule, TranslateModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input()
  variant: Variants = 'primary';
  @Input()
  text: string | string[];
  @Input()
  alternativeActions?: ActionItem[];
  @Input()
  disabled?: boolean;
  @Input()
  isLoading?: boolean = false;

  @Output()
  menuItemClick = new EventEmitter<string>();

  get isSplitButton(): boolean {
    return this.alternativeActions && this.alternativeActions.length > 0;
  }

  getButtonClasses() {
    return {
      [this.variant]: true,
      split: this.isSplitButton,
    };
  }

  onMenuItemClick(actionItem: ActionItem) {
    actionItem.action();
    this.menuItemClick.emit(actionItem.text);
  }

  onArrowClick(event: Event) {
    event.stopPropagation();
  }
}
