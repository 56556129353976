import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rkb-form-field',
  standalone: true,
  imports: [],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldComponent {
  @Input()
  fullWidth = false;

  @Input()
  mbClass = 'mb-6';

  @Input()
  inline = false;
}
