import moment from 'moment';

export interface BrandApiItem {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  cluster?: string;
  default_timezone: string | null;
  default_language: string | null;
  default_country_code: string | null;
  default_radio_url: string | null;
}

export interface Brand {
  id: string;
  name: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  cluster?: string;
  defaultTimezone: string | null;
  defaultLanguage: string | null;
  defaultCountryCode: string | null;
  defaultRadioUrl: string | null;
}

export function convertToBrands(apiItems: BrandApiItem[]): Brand[] {
  return apiItems.map(apiItem => ({
    id: apiItem.id,
    name: apiItem.name,
    createdAt: moment(apiItem.created_at),
    updatedAt: moment(apiItem.updated_at),
    cluster: apiItem.cluster,
    defaultTimezone: apiItem.default_timezone,
    defaultLanguage: apiItem.default_language,
    defaultCountryCode: apiItem.default_country_code,
    defaultRadioUrl: apiItem.default_radio_url,
  }));
}

export function convertToApiBrandItem(brand: Partial<Brand>): Partial<BrandApiItem> {
  return {
    id: brand.id,
    name: brand.name,
    created_at: brand.createdAt?.toISOString(),
    updated_at: brand.updatedAt?.toISOString(),
    cluster: brand.cluster,
    default_timezone: brand.defaultTimezone,
    default_language: brand.defaultLanguage,
    default_country_code: brand.defaultCountryCode,
    default_radio_url: brand.defaultRadioUrl,
  };
}

export function convertBrandApiToBrand(brand: BrandApiItem): Brand {
  return {
    id: brand.id,
    name: brand.name,
    createdAt: moment(brand.created_at),
    updatedAt: moment(brand.updated_at),
    cluster: brand.cluster,
    defaultTimezone: brand.default_timezone,
    defaultLanguage: brand.default_language,
    defaultCountryCode: brand.default_country_code,
    defaultRadioUrl: brand.default_radio_url,
  };
}
