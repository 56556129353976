export interface ProgramApiItem {
  brand_id: string;
  certification_id: string;
  country_code: string;
  created_at: string;
  description: string;
  genre: string;
  id: string;
  language: string;
  name: string;
  radio_url: string;
  tags: string[];
  timezone: string;
  updated_at: string;
}
export interface Program {
  brandId: string;
  certificationId: string;
  countryCode: string;
  createdAt: Date;
  description: string;
  genre: string;
  id: string;
  language: string;
  name: string;
  radioUrl: string;
  tags: string[];
  timezone: string;
  updatedAt: Date;
}

export function convertToProgram(apiItem: ProgramApiItem): Program {
  return {
    brandId: apiItem.brand_id,
    certificationId: apiItem.certification_id,
    countryCode: apiItem.country_code,
    createdAt: new Date(apiItem.created_at),
    description: apiItem.description,
    genre: apiItem.genre,
    id: apiItem.id,
    language: apiItem.language,
    name: apiItem.name,
    radioUrl: apiItem.radio_url,
    tags: apiItem.tags,
    timezone: apiItem.timezone,
    updatedAt: new Date(apiItem.updated_at),
  };
}

export function convertToApiProgramItem(
  program: Partial<Program>,
): Partial<ProgramApiItem> {
  return {
    brand_id: program.brandId,
    country_code: program.countryCode,
    language: program.language,
    name: program.name,
    timezone: program.timezone,
    radio_url: program.radioUrl,
    description: program.description,
    tags: program.tags,
    certification_id: program.certificationId,
    genre: program.genre,
  };
}
