import { registerLocaleData } from '@angular/common';
import enGB from '@angular/common/locales/en-GB';
import fr from '@angular/common/locales/fr';
import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import enUS from '../../assets/i18n/en.json';
import frFR from '../../assets/i18n/fr.json';

const languageKey = 'language';

const removeEmpty = (obj: any) => {
  if (!obj) {
    return;
  }
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] === '') {
      delete obj[key];
    }
  });
};

removeEmpty(enUS);
removeEmpty(frFR);

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage: string;
  supportedLanguages: string[];

  langSubject = new ReplaySubject<string>();
  supportedLangsSubject = new ReplaySubject<string[]>();

  constructor(private readonly translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    this.translateService.setTranslation('en-US', enUS);
    this.translateService.setTranslation('en-GB', enUS);
    this.translateService.setTranslation('fr-FR', frFR);
    registerLocaleData(enGB, 'en-GB');
    registerLocaleData(fr, 'fr-FR');
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang || 'en-US';
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    let lang = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    const supportedLanguages = this.supportedLanguages || [];
  
    let isSupportedLanguage = supportedLanguages.indexOf(lang) > -1;
  
    // If no exact match is found, search without the region
    if (lang && !isSupportedLanguage) {
      const langNoRegion = lang.split('-')[0];
      lang = supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(langNoRegion)) || '';
      isSupportedLanguage = Boolean(lang);
    }
  
    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      lang = this.defaultLanguage;
    }
  
    this.translateService.use(lang);
    moment.locale(lang.substr(0, 2));
    window.localStorage.setItem('mg-locale', lang.substr(0, 2));
  }
  
  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init() {
    this.defaultLanguage = 'en-US';
    this.supportedLanguages = ['en-US', 'fr-FR', 'en-GB'];
    this.supportedLangsSubject.next(this.supportedLanguages);
    this.language = this.defaultLanguage;

    // Set fallback lang to default lang
    this.translateService.setDefaultLang(this.defaultLanguage);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(languageKey, event.lang);
      this.langSubject.next(event.lang);
    });
  }
}
