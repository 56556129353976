{
  "BRAND_ENCODING": {
    "BITRATE": "Bitrate",
    "CHANNELS": "Channels",
    "CODEC": "Codec",
    "CREATE": "Create Encoding",
    "FREQUENCY": "Frequency",
    "LABEL": "Label"
  },
  "BRAND_SETTINGS": {
    "COUNTRY": "Country",
    "LANGUAGE": "Language",
    "NAME": "Name",
    "NAME_PLACEHOLDER": "Brand Name",
    "RADIO_URL": "Radio URL",
    "RADIO_URL_PLACEHOLDER": "Radio URL",
    "TIMEZONE": "Timezone"
  },
  "BRAND_SOURCE": {
    "AUDIO_FILE": "Audio file",
    "CODEC": "Codec",
    "CREATE": "Create source",
    "CREATE_FILE_SOURCE": "Add audio file",
    "FAILOVER": "Failover source",
    "SECURED_IP": "Secured on IP",
    "SOURCE": "Source",
    "STATUS": "Status",
    "TYPE": "Type"
  },
  "DIALOG_SECRET_KEY": {
    "NEW_TITLE": "Your secret key has been regenerated",
    "REGENERATE": "Regenerate",
    "TITLE": "Are you sure you want to regenerate your secret key?"
  },
  "DIALOG_SOURCE_CREATED": {
    "HINT": "Here are the connection details for your new sources.",
    "NOTED": "Close, I've taken note",
    "SECRET_KEY_WARNING": "For security reasons, your secret key is visible only once when it is created. Remember to keep it in a safe place.",
    "TITLE": "Your source has been created"
  },
  "GENRE": {
    "BLUES": {
      "ACOUSTIC": "Acoustic blues",
      "CHICAGO": "Chicago blues",
      "CLASSIC": "Classic blues",
      "COUNTRY": "Country blues",
      "DELTA": "Delta blues",
      "ROCK": "Rock blues"
    },
    "CLASSIC": {
      "BAROQUE": "Baroque",
      "MEDIEVAL": "Medieval",
      "MODERN": "Modern",
      "OPERA": "Opera",
      "REBIRTH": "Renaissance",
      "ROMANTIC": "Romantic"
    },
    "COUNTRY": {
      "ALTERNATIVE": "Alternative country",
      "BLUEGRASS": "Bluegrass",
      "CLASSIC": "Classic country",
      "POP": "Pop country",
      "ROCKABILLY": "Rockabilly"
    },
    "ELECTRO": {
      "CLUBBING": "Dance / Clubbing",
      "ELECTRO": "Electro",
      "HOUSE": "House",
      "LOUNGE": "Lounge / Ambiant",
      "TECHNO": "Techno",
      "TRIP_HOP": "Trip Hop"
    },
    "HITS": {
      "60": "Hits 60s",
      "70": "Hits 70s",
      "80": "Hits 80s",
      "90": "Hits 90s",
      "FRENCH": "French hits",
      "GOLD": "Gold",
      "HITS": "Hits",
      "UK_USA": "UK / USA hits"
    },
    "INFOS": {
      "CULTURE": "Culture",
      "HEALTH": "Health",
      "HUMOR": "Humor",
      "LOCAL": "Local",
      "NEWS": "News",
      "POLITIC": "Politic",
      "RELIGION": "Religion",
      "SPORT": "Sport"
    },
    "JAZZ": {
      "BEBOP": "Bepop",
      "BIG_BAND": "Big band",
      "CONTEMPORARY": "Contemporary jazz",
      "INSTRUMENTAL": "Instrumental jazz",
      "SMOOTH": "Smooth Jazz",
      "SWING": "Swing",
      "VOCAL": "Vocal jazz"
    },
    "METAL": {
      "BLACK": "Black metal",
      "DOOM": "Doom metal",
      "HEAVY": "Heavy metal",
      "INDUSTRIAL": "Industrial metal",
      "POWER": "Power metal",
      "PROGRESSIVE": "Progressive metal",
      "SPEED": "Speed metal"
    },
    "MISC": {
      "FILM_MUSIC": "Film music",
      "LOVE_SLOW": "Love / Slow",
      "OTHER": "Other",
      "VINTAGE": "Vintage",
      "YOUNTH": "Youth",
      "ZEN": "Zen"
    },
    "POP_ROCK": {
      "BRITPOP": "Britpop",
      "CLASSIC_ROCK": "Classic rock",
      "FOLK": "Folk",
      "FRENCH": "French Pop/Rock",
      "INDIE": "Indie",
      "NEW_WAVE": "New Wave",
      "POP": "Pop",
      "ROCK": "Rock"
    },
    "REGGAE": {
      "DANCEHALL": "Dancehall",
      "DUB": "Dub",
      "REGGAE": "Reggae",
      "SKA": "Ska"
    },
    "URBAIN": {
      "DISCO": "Disco",
      "FUNK": "Funk",
      "GOSPEL": "Gospel",
      "HIP_HOP": "Hip Hop",
      "RAP": "Rap",
      "RNB": "RnB",
      "SOUL": "Soul"
    },
    "WORLD": {
      "AFRICA": "Africa",
      "ASIA": "Asia",
      "BRASIL": "Brazil",
      "CENTRAL_AMERICA": "Central America",
      "CHINA": "China",
      "EUROPE": "Europe",
      "INDIA": "India",
      "MAGHREB": "Maghreb",
      "MIDDLE_EAST": "Middle East",
      "NORTH_AMERICA": "North America",
      "PACIFICA": "Australia / Pacific",
      "SOUTH_AMERICA": "South America"
    }
  },
  "GLOBAL": {
    "ACTIVE": "Active",
    "COPY_CLIPBOARD": "Copy to clipboard.",
    "EDIT": "Edit",
    "NO": "No",
    "SEARCH": "Search",
    "SEE": "See",
    "YES": "Yes"
  },
  "HEADER": {
    "DASHBOARD": "Dashboard",
    "MONITORING": "Monitoring",
    "ORGANIZATION": "Organization",
    "STATISTICS": "Statistics"
  },
  "ORG": {
    "SIDE": {
      "BRAND": "Brand"
    }
  },
  "ORG_BRANDS": {
    "BRANDS_AND_PROGRAMS": "Brands & Programs",
    "CREATE": "Create a program",
    "CREATE_BRAND": "Create a brand",
    "DELETE_PROGRAM": "Delete program",
    "LAST_MODIFICATION": "Last modification"
  },
  "PROGRAM_INPUT": {
    "BACKUP": "Backup stream",
    "CREATE": "Create an input",
    "INPUTS": "Inputs & sources"
  },
  "PROGRAM_SETTINGS": {
    "API_ID": "API ID",
    "COUNTRY": "Country",
    "DESCRIPTION": "Description",
    "DESCRIPTION_PLACEHOLDER": "The description of your program",
    "GENRE": "Genre",
    "LOCALIZATION": "Localization",
    "NAME": "Name",
    "NAME_PLACEHOLDER": "The name of your program",
    "RADIO_URL": "Website URL",
    "RADIO_URL_PLACEHOLDER": "The URL of your website",
    "TAGS": "Tags",
    "TAGS_PLACEHOLDER": "Add tags to your program",
    "TIMEZONE": "Timezone"
  },
  "SETTINGS": {
    "CANCEL": "Cancel",
    "SUBMIT": "Save"
  },
  "SET_ENCODING": {
    "BITRATE": "Bitrate",
    "CANCEL": "Cancel",
    "CHANNELS": "Channels",
    "CODEC": "Codec",
    "CREATE": "Create Encoding",
    "FREQUENCY": "Frequency",
    "LABEL": "Encoding Label",
    "SUBMIT": "Submit"
  },
  "SIDE": {
    "CANCEL": "Cancel"
  },
  "SIDE_CONSULT_SOURCE": {
    "AUTHORIZED_IPS": "Authorized IPs",
    "LOGIN_INFORMATIONS": "Login informations",
    "MOUNTPOINT": "Mount point",
    "REGENERATE_KEY": "Regenerate secret key",
    "SECRET_KEY_HINT": "For security reasons, your secret key is visible only once when it is created."
  },
  "SIDE_CREATE_BRAND": {
    "CLUSTER": "Cluster",
    "CREATE": "Create a brand",
    "LABEL": "Brand label",
    "SELECT_CLUSTER": "Select a cluster"
  },
  "SIDE_CREATE_PROGRAM": {
    "COUNTRY": "Country",
    "CREATE": "Create a program",
    "LABEL": "Program label",
    "LANGUAGE": "Language",
    "LINK_A_BRAND": "Link a brand",
    "SELECT_BRAND": "Select a brand",
    "SELECT_COUNTRY": "Select a country",
    "SELECT_LANGUAGE": "Select a language",
    "SELECT_TIMEZONE": "Select a timezone",
    "TIMEZONE": "Timezone"
  },
  "SIDE_CREATE_SOURCE": {
    "AUDIO_FILE": "",
    "IPBAN_DROPDOWN": "Add \"{ip}\"",
    "IPBAN_PLACEHOLDER": "Add an IP address...",
    "LABEL": "Source label"
  },
  "SUB_MENU": {
    "BRAND": {
      "ENCODING": "Encodings",
      "SECURITY": "Security",
      "SETTINGS": "Settings",
      "SOURCES": "Sources"
    },
    "ORGANIZATION": {
      "MEMBERS": "Members",
      "ORGANIZATION": "Brands & Programs",
      "SETTINGS": "Settings"
    },
    "PROGRAM": {
      "ENTRIES_SOURCES": "Entries & sources",
      "OUTPUT": "Outputs"
    }
  }
}
