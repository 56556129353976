import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { CloseDrawer } from '../../../shell/states/drawer.actions';
import { Brand } from '../../brand/models/brand.model';
import { Org } from '../models/org.model';
import { Program } from '../models/program.model';
import { OrgService } from '../services/org.service';
import {
  CreateBrandError,
  CreateBrandRequest,
  CreateBrandSuccess,
  CreateProgramError,
  CreateProgramRequest,
  CreateProgramSuccess,
  FetchOrgBrandsError,
  FetchOrgBrandsRequest,
  FetchOrgBrandsSuccess,
  FetchOrgError,
  FetchOrgProgramsError,
  FetchOrgProgramsRequest,
  FetchOrgProgramsSuccess,
  FetchOrgRequest,
  FetchOrgSuccess,
  ResetNewBrandId,
  ResetNewProgramId,
  SetCurrentOrgId,
} from './org.actions';

export class OrgStateModel {
  org: Org;
  brands: Brand[];
  programs: Program[];
  currentOrgId: string;
  isLoading: boolean;
  newBrandId: string;
  newProgramId: string;
}

@State<OrgStateModel>({
  name: 'Org',
  defaults: {
    org: null,
    brands: [],
    programs: [],
    isLoading: false,
    currentOrgId: '0',
    newBrandId: null,
    newProgramId: null,
  },
})
@Injectable()
export class OrgState {
  constructor(private readonly orgService: OrgService) {}

  @Selector()
  static org(state: OrgStateModel): Org {
    return state.org;
  }

  @Selector()
  static allowedClusters(state: OrgStateModel): string[] {
    return state.org.allowedClusters;
  }

  @Selector()
  static brands(state: OrgStateModel): Brand[] {
    return state.brands;
  }

  @Selector()
  static programs(state: OrgStateModel): Program[] {
    return state.programs;
  }

  @Selector()
  static orgId(state: OrgStateModel): string | null {
    return state.currentOrgId;
  }

  @Selector()
  static newBrandId(state: OrgStateModel): string | null {
    return state.newBrandId;
  }

  @Selector()
  static newProgramId(state: OrgStateModel): string | null {
    return state.newProgramId;
  }

  @Action(SetCurrentOrgId)
  setCurrentOrgId(ctx: StateContext<OrgStateModel>, { orgId }: SetCurrentOrgId) {
    ctx.patchState({
      currentOrgId: orgId,
    });

    ctx.dispatch(new FetchOrgRequest(orgId));
  }

  // Fetch org

  @Action(FetchOrgRequest)
  fetchOrgRequest(ctx: StateContext<OrgStateModel>, { orgId }: FetchOrgRequest) {
    ctx.patchState({
      isLoading: true,
    });

    return this.orgService.getOrg(orgId).pipe(
      tap((org: Org) => {
        ctx.dispatch(new FetchOrgSuccess(org));
      }),
      catchError(error => ctx.dispatch(new FetchOrgError(error))),
    );
  }

  @Action(FetchOrgSuccess)
  fetchOrgSuccess(ctx: StateContext<OrgStateModel>, { org }: FetchOrgSuccess) {
    ctx.patchState({
      org,
      isLoading: false,
    });
  }

  @Action(FetchOrgError)
  fetchOrgError(ctx: StateContext<OrgStateModel>, { error }: FetchOrgError) {
    ctx.patchState({
      isLoading: false,
    });
  }

  // Fetch org brands

  @Action(FetchOrgBrandsRequest)
  fetchOrgBrandsRequest(ctx: StateContext<OrgStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    const orgId = ctx.getState().currentOrgId;

    return this.orgService.getOrgBrands(orgId).pipe(
      tap((apiResponse: Brand[]) => {
        ctx.dispatch(new FetchOrgBrandsSuccess(apiResponse));
      }),
      catchError(error => ctx.dispatch(new FetchOrgBrandsError(error))),
    );
  }

  @Action(FetchOrgBrandsSuccess)
  fetchOrgBrandsSuccess(
    ctx: StateContext<OrgStateModel>,
    { brands }: FetchOrgBrandsSuccess,
  ) {
    ctx.patchState({
      brands,
      isLoading: false,
    });
  }
  // Fetch org programs
  @Action(FetchOrgProgramsRequest)
  fetchOrgProgramsRequest(ctx: StateContext<OrgStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    const orgId = ctx.getState().currentOrgId;

    return this.orgService.getOrgPrograms(orgId).pipe(
      tap((apiResponse: Program[]) => {
        ctx.dispatch(new FetchOrgProgramsSuccess(apiResponse));
      }),
      catchError(error => ctx.dispatch(new FetchOrgProgramsError(error))),
    );
  }

  @Action(FetchOrgProgramsSuccess)
  fetchOrgProgramsSuccess(
    ctx: StateContext<OrgStateModel>,
    { programs }: FetchOrgProgramsSuccess,
  ) {
    ctx.patchState({
      programs,
      isLoading: false,
    });
  }

  // Create program
  @Action(CreateProgramRequest)
  createProgramRequest(
    ctx: StateContext<OrgStateModel>,
    { program }: CreateProgramRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    const brandId = program.brandId;

    return this.orgService.createProgram(brandId, program).pipe(
      tap((newProgram) => {
        ctx.dispatch(new CreateProgramSuccess(newProgram.id));
      }),
      catchError(error => ctx.dispatch(new CreateProgramError(error))),
    );
  }

  @Action(CreateProgramSuccess)
  createProgramSuccess(ctx: StateContext<OrgStateModel>, { newProgramId }: CreateProgramSuccess) {
    ctx.patchState({
      newProgramId,
      isLoading: false,
    });

    ctx.dispatch(new FetchOrgProgramsRequest());
    ctx.dispatch(new CloseDrawer());
  }

  @Action(CreateProgramError)
  createProgramError(ctx: StateContext<OrgStateModel>, { error }: CreateProgramError) {
    ctx.patchState({
      isLoading: false,
    });
  }

  // Create Brand

  @Action(CreateBrandRequest)
  createBrandRequest(ctx: StateContext<OrgStateModel>, { brand }: CreateBrandRequest) {
    ctx.patchState({
      isLoading: true,
    });

    const orgId = ctx.getState().currentOrgId;

    return this.orgService.createBrand(orgId, brand).pipe(
      tap((newBrand) => {
        const newBrandId = newBrand.id;
        ctx.dispatch(new CreateBrandSuccess(newBrandId));
        ctx.dispatch(new FetchOrgBrandsRequest());
      }),
      catchError(error => ctx.dispatch(new CreateBrandError(error))),
    );
  }

  @Action(CreateBrandSuccess)
  createBrandSuccess(ctx: StateContext<OrgStateModel>, { newBrandId }: CreateBrandSuccess) {
    ctx.patchState({
      newBrandId,
      isLoading: false,
    });

    ctx.dispatch(new FetchOrgBrandsRequest());
    ctx.dispatch(new CloseDrawer());
  }

  @Action(CreateBrandError)
  createBrandError(ctx: StateContext<OrgStateModel>, { error }: CreateBrandError) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(ResetNewBrandId)
  resetNewBrandId(ctx: StateContext<OrgStateModel>) {
    ctx.patchState({
      newBrandId: null,
    });
  }

  @Action(ResetNewProgramId)
  resetNewProgramId(ctx: StateContext<OrgStateModel>) {
    ctx.patchState({
      newProgramId: null,
    });
  }
}
