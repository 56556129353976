import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Brand,
  BrandApiItem,
  convertToApiBrandItem,
  convertToBrands,
} from '../../brand/models/brand.model';
import {
  convertToApiProgramItem,
  convertToProgram,
  Program,
  ProgramApiItem,
} from '../models/program.model';
import { convertToOrg, Org, OrgApiItem } from '../models/org.model';

const ORG_URLS = {
  getOrg: (id: string) => `${environment.urls.RK_BUSINESS_API}/organizations/${id}`,
  getOrgBrands: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/organizations/${id}/brands`,
  getOrgPrograms: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/organizations/${id}/programs`,
  createProgram: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/brands/${id}/programs`,
  createBrand: (id: string) =>
    `${environment.urls.RK_BUSINESS_API}/organizations/${id}/brands`,
};

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  constructor(private readonly http: HttpClient) {}

  getOrg(id: string): Observable<Org> {
    return this.http
      .get<OrgApiItem>(ORG_URLS.getOrg(id))
      .pipe(map((orgApiItem: OrgApiItem) => convertToOrg(orgApiItem)));
  }

  getOrgBrands(id: string): Observable<Brand[]> {
    return this.http
      .get<BrandApiItem[]>(ORG_URLS.getOrgBrands(id))
      .pipe(map((brandApiItems: BrandApiItem[]) => convertToBrands(brandApiItems)));
  }

  getOrgPrograms(id: string): Observable<Program[]> {
    return this.http
      .get<ProgramApiItem[]>(ORG_URLS.getOrgPrograms(id))
      .pipe(
        map((programApiItems: ProgramApiItem[]) => programApiItems.map(convertToProgram)),
      );
  }

  createProgram(id: string, program: Partial<Program>): Observable<ProgramApiItem> {
    return this.http.post<ProgramApiItem>(
      ORG_URLS.createProgram(id),
      convertToApiProgramItem(program),
    );
  }

  createBrand(id: string, brand: Partial<Brand>): Observable<BrandApiItem> {
    return this.http.post<BrandApiItem>(
      ORG_URLS.createBrand(id),
      convertToApiBrandItem(brand),
    );
  }
}
