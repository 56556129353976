{
  "BRAND_ENCODING": {
    "BITRATE": "Bitrate",
    "CHANNELS": "Canaux",
    "CODEC": "Codec",
    "CREATE": "Créer un encodage",
    "FREQUENCY": "Fréquence",
    "LABEL": "Libellé"
  },
  "BRAND_SETTINGS": {
    "COUNTRY": "Pays",
    "LANGUAGE": "Langue",
    "NAME": "Nom",
    "NAME_PLACEHOLDER": "Nom de la marque",
    "RADIO_URL": "URL Radio",
    "RADIO_URL_PLACEHOLDER": "URL Radio",
    "TIMEZONE": "Timezone"
  },
  "BRAND_SOURCE": {
    "AUDIO_FILE": "Fichier audio",
    "CODEC": "Codec",
    "CREATE": "Créer une source",
    "CREATE_FILE_SOURCE": "Ajouter un fichier audio",
    "FAILOVER": "Source de secours",
    "SECURED_IP": "Sécurisé sur IP",
    "SOURCE": "Source",
    "STATUS": "Statut",
    "TYPE": "Type"
  },
  "DIALOG_SECRET_KEY": {
    "NEW_TITLE": "Votre secret key à été regénérée",
    "REGENERATE": "Regénérer",
    "TITLE": "Êtes-vous certain de vouloir regénérer votre secret key ?"
  },
  "DIALOG_SOURCE_CREATED": {
    "HINT": "Voici les informations de connexion de vos nouvelles sources.",
    "NOTED": "Fermer, j'ai pris note",
    "SECRET_KEY_WARNING": "Pour des raisons de sécurité, votre clé secrète est visible une seule fois lors de sa création. Pensez à la conserver dans un endroit sécurisé.",
    "TITLE": "Votre source a été crée"
  },
  "GENRE": {
    "BLUES": {
      "ACOUSTIC": "Blues acoustique",
      "CHICAGO": "Chicago blues",
      "CLASSIC": "Blues classique",
      "COUNTRY": "Country blues",
      "DELTA": "Delta blues",
      "ROCK": "Rock blues"
    },
    "CLASSIC": {
      "BAROQUE": "Baroque",
      "MEDIEVAL": "Médiéval",
      "MODERN": "Moderne",
      "OPERA": "Opéra",
      "REBIRTH": "Renaissance",
      "ROMANTIC": "Romantique"
    },
    "COUNTRY": {
      "ALTERNATIVE": "Country alternative",
      "BLUEGRASS": "Bluegrass",
      "CLASSIC": "Country classique",
      "POP": "Pop country",
      "ROCKABILLY": "Rockabilly"
    },
    "ELECTRO": {
      "CLUBBING": "Dance / Clubbing",
      "ELECTRO": "Electro",
      "HOUSE": "House",
      "LOUNGE": "Lounge / Ambiant",
      "TECHNO": "Techno",
      "TRIP_HOP": "Trip Hop"
    },
    "HITS": {
      "60": "Hits 60s",
      "70": "Hits 70s",
      "80": "Hits 80s",
      "90": "Hits 90s",
      "FRENCH": "Hits français",
      "GOLD": "Gold",
      "HITS": "Hits",
      "UK_USA": "Hits UK / USA"
    },
    "INFOS": {
      "CULTURE": "Culture",
      "HEALTH": "Santé",
      "HUMOR": "Humour",
      "LOCAL": "Local",
      "NEWS": "Infos",
      "POLITIC": "Politique",
      "RELIGION": "Religion",
      "SPORT": "Sport"
    },
    "JAZZ": {
      "BEBOP": "Bepop",
      "BIG_BAND": "Big band",
      "CONTEMPORARY": "Jazz contemporain",
      "INSTRUMENTAL": "Jazz instrumental",
      "SMOOTH": "Smooth Jazz",
      "SWING": "Swing",
      "VOCAL": "Jazz vocal"
    },
    "METAL": {
      "BLACK": "Black metal",
      "DOOM": "Doom metal",
      "HEAVY": "Heavy metal",
      "INDUSTRIAL": "Industrial metal",
      "POWER": "Power metal",
      "PROGRESSIVE": "Progressive metal",
      "SPEED": "Speed metal"
    },
    "MISC": {
      "FILM_MUSIC": "Musique de film",
      "LOVE_SLOW": "Love / Slow",
      "OTHER": "Autre",
      "VINTAGE": "Vintage",
      "YOUNTH": "Jeunesse",
      "ZEN": "Zen"
    },
    "POP_ROCK": {
      "BRITPOP": "Britpop",
      "CLASSIC_ROCK": "Rock classique",
      "FOLK": "Folk",
      "FRENCH": "Pop/Rock français",
      "INDIE": "Indie",
      "NEW_WAVE": "New Wave",
      "POP": "Pop",
      "ROCK": "Rock"
    },
    "REGGAE": {
      "DANCEHALL": "Dancehall",
      "DUB": "Dub",
      "REGGAE": "Reggae",
      "SKA": "Ska"
    },
    "URBAIN": {
      "DISCO": "Disco",
      "FUNK": "Funk",
      "GOSPEL": "Gospel",
      "HIP_HOP": "Hip Hop",
      "RAP": "Rap",
      "RNB": "RnB",
      "SOUL": "Soul"
    },
    "WORLD": {
      "AFRICA": "Afrique",
      "ASIA": "Asie",
      "BRASIL": "Brésil",
      "CENTRAL_AMERICA": "Amérique centrale",
      "CHINA": "Chine",
      "EUROPE": "Europe",
      "INDIA": "Inde",
      "MAGHREB": "Maghreb",
      "MIDDLE_EAST": "Moyen Orient",
      "NORTH_AMERICA": "Amérique du nord",
      "PACIFICA": "Australie / Pacifique",
      "SOUTH_AMERICA": "Amérique du sud"
    }
  },
  "GLOBAL": {
    "ACTIVE": "Actif",
    "COPY_CLIPBOARD": "Copié dans le presse-papier.",
    "EDIT": "Editer",
    "NO": "Non",
    "SEARCH": "Rechercher",
    "SEE": "Voir",
    "YES": "Oui"
  },
  "HEADER": {
    "DASHBOARD": "Tableau de bord",
    "MONITORING": "Monitoring",
    "ORGANIZATION": "Organisation",
    "STATISTICS": "Statistiques"
  },
  "ORG": {
    "SIDE": {
      "BRAND": "Marque"
    }
  },
  "ORG_BRANDS": {
    "BRANDS_AND_PROGRAMS": "Marques & Programmes",
    "CREATE": "Créer un programme",
    "CREATE_BRAND": "Créer une marque",
    "DELETE_PROGRAM": "Supprimer le programme",
    "LAST_MODIFICATION": "Dernière modification"
  },
  "PROGRAM_INPUT": {
    "BACKUP": "Flux de secours",
    "CREATE": "Créer une entrée",
    "INPUTS": "Entrées & sources"
  },
  "PROGRAM_SETTINGS": {
    "API_ID": "ID d'API",
    "COUNTRY": "Pays",
    "DESCRIPTION": "Description",
    "DESCRIPTION_PLACEHOLDER": "La description de votre programme",
    "GENRE": "Genre",
    "LOCALIZATION": "Localisation",
    "NAME": "Nom",
    "NAME_PLACEHOLDER": "Le nom de votre programme",
    "RADIO_URL": "Url du site",
    "RADIO_URL_PLACEHOLDER": "L'URL de votre site",
    "TAGS": "Tags",
    "TAGS_PLACEHOLDER": "Ajouter des tags à votre programme",
    "TIMEZONE": "Timezone"
  },
  "SETTINGS": {
    "CANCEL": "Annuler",
    "SUBMIT": "Enregistrer"
  },
  "SET_ENCODING": {
    "BITRATE": "Bitrate",
    "CANCEL": "Annuler",
    "CHANNELS": "Canaux",
    "CODEC": "Codec",
    "CREATE": "Créer un encodage",
    "FREQUENCY": "Fréquence",
    "LABEL": "Libellé de l'encodage",
    "SUBMIT": "Planifier"
  },
  "SIDE": {
    "CANCEL": "Annuler"
  },
  "SIDE_CONSULT_SOURCE": {
    "AUTHORIZED_IPS": "IPs autorisées",
    "LOGIN_INFORMATIONS": "Informations de connexion",
    "MOUNTPOINT": "Point de montage",
    "REGENERATE_KEY": "Regénérer la secret key",
    "SECRET_KEY_HINT": "Pour des raisons de sécurité, votre clé secrète est visible une seule fois lors de sa création."
  },
  "SIDE_CREATE_BRAND": {
    "CLUSTER": "Cluster",
    "CREATE": "Créer une marque",
    "LABEL": "Libellé de la marque",
    "SELECT_CLUSTER": "Sélectionner un cluster"
  },
  "SIDE_CREATE_PROGRAM": {
    "COUNTRY": "Pays",
    "CREATE": "Créer un programme",
    "LABEL": "Libellé du programme",
    "LANGUAGE": "Langue",
    "LINK_A_BRAND": "Lier une marque",
    "SELECT_BRAND": "Sélectionner une marque",
    "SELECT_COUNTRY": "Sélectionner un pays",
    "SELECT_LANGUAGE": " Sélectionner une langue",
    "SELECT_TIMEZONE": "Sélectionner une timezone",
    "TIMEZONE": "Timezone"
  },
  "SIDE_CREATE_SOURCE": {
    "AUDIO_FILE": "",
    "IPBAN_DROPDOWN": "Ajouter \"{ip}\"",
    "IPBAN_PLACEHOLDER": "Ajouter une adresse IP...",
    "LABEL": "Libellé de la source"
  },
  "SUB_MENU": {
    "BRAND": {
      "ENCODING": "Encodages",
      "SECURITY": "Sécurité",
      "SETTINGS": "Paramètres",
      "SOURCES": "Sources"
    },
    "ORGANIZATION": {
      "MEMBERS": "Membres",
      "ORGANIZATION": "Marques & Programmes",
      "SETTINGS": "Paramètres"
    },
    "PROGRAM": {
      "ENTRIES_SOURCES": "Entrées & sources",
      "OUTPUT": "Sorties"
    }
  }
}
