import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: false,
  authEnabled: true,
  urls: {
    RK_BUSINESS_API: 'https://api-rkb.dev.radioking.xyz/api',
  },
  cookies: {
    config: {
      domain: '.dev.radioking.xyz',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
  gtm: 'GTM-TDV8W4V7',
};
