<div class="flex">
  <button [disabled]="disabled" [ngClass]="getButtonClasses()">
    <div class="content-container">
      <span [class.text-hidden]="isLoading">{{ text }}</span>
      <!-- spinner -->
      <mat-progress-spinner
        *ngIf="isLoading"
        class="spinner"
        mode="indeterminate"
        [strokeWidth]="2"
        [diameter]="20"
      ></mat-progress-spinner>
    </div>
  </button>

  <!-- alternative actions dropdown -->
  <ng-container *ngIf="isSplitButton">
    <div
      class="split-button-arrow bg-[#fd3c4c] px-3 flex items-center justify-center rounded-r-[12px] cursor-pointer border-l-2 border-white"
      [matMenuTriggerFor]="menu"
      (click)="onArrowClick($event)"
    >
      <!-- arrow icon -->
      <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.25L4.5 4.75L8 1.25"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <!-- list of actions -->
    <mat-menu #menu="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let actionItem of alternativeActions"
        (click)="onMenuItemClick(actionItem)"
        class="h-[40px]"
      >
        {{ actionItem.text | translate }}
      </button>
    </mat-menu>
  </ng-container>
</div>
