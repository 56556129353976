<div>
  <rkb-form-field>
    <div class="relative">
      <input
        type="text"
        [placeholder]="placeholderKey | translate"
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        (click)="open()" 
      />
      <rkb-svg-icon
        icon="chevron-down"
        class="absolute right-3 top-[20px] cursor-pointer"
        (click)="open()"
        [class.rotate]="isOpen"
        size="14px"
        [dark]="true"
      >
      </rkb-svg-icon>
    </div>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (closed)="onPanelClosed()">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.label | translate }}
      </mat-option>
    </mat-autocomplete>
  </rkb-form-field>
</div>
