import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { DropdownOption } from '../../models/dropdown-option.model';
import { FormFieldComponent } from '../form-field/form-field.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'rkb-input-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormFieldComponent,
    MatInputModule,
    SvgIconComponent,
    TranslateModule,
  ],
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent implements OnInit {
  @Input()
  options: DropdownOption[] = [];

  @Input()
  searchControl: FormControl;

  @Input()
  placeholderKey = marker('GLOBAL.SEARCH');

  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;

  filteredOptions: Observable<DropdownOption[]>;

  constructor(private translateService: TranslateService) {}

  isOpen = false;

  ngOnInit() {
    if (!this.searchControl) {
      this.searchControl = new FormControl();
    }

    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value?.label || '')),
      map(label => this._filter(label)),
    );
  }

  private _filter(value: string): DropdownOption[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.label.toLowerCase().includes(filterValue),
    );
  }

  displayFn = (option: DropdownOption): string => {
    if (!option) return '';
    return this.translateService.instant(option.label);
  };

  open() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.autoTrigger.openPanel();
    } else {
      this.autoTrigger.closePanel();
    }
  }

  onPanelClosed() {
    this.isOpen = false;
  }
}
