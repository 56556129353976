import { DynamicItem } from './drawer.types';

const tag = '[Drawer]';

export class SetCurrentDrawer {
  static readonly type = `${tag} set current drawer`;
  constructor(public currentDrawer: DynamicItem) {}
}

export class CloseDrawer {
  static readonly type = `${tag} close drawer`;
  constructor() {}
}
